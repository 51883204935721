import React, { useEffect } from 'react';

const TelegramWidget = ({ channelName }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://telegram.org/js/telegram-widget.js?22';
    script.async = true;
    
    // Konfiguration für den Channel Feed
    script.setAttribute('data-telegram-post', channelName + "/last"); // Erster Post als Anker
    script.setAttribute('data-width', '100%');
    script.setAttribute('data-color', '2481cc');
    script.setAttribute('data-dark', '0');
    script.setAttribute('data-multiple', '1'); // Mehrere Posts anzeigen
    script.setAttribute('data-limit', '5');    // Limit auf 5 Posts
    script.setAttribute('data-author-photo', 'true');

    const container = document.getElementById('telegram-container');
    if (container) {
      container.innerHTML = '';
      container.appendChild(script);
    }

    return () => {
      const container = document.getElementById('telegram-container');
      if (container) {
        container.innerHTML = '';
      }
    };
  }, [channelName]);

  return (
    <div className="w-full max-w-2xl mx-auto">
      <div 
        id="telegram-container" 
        className="w-full overflow-hidden bg-white rounded-lg shadow-md"
        style={{ minHeight: '200px' }}
      ></div>
    </div>
  );
};

export default TelegramWidget;